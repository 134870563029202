.zLoader {
  position     : absolute;
  top          : 0;
  right        : 0;
  left         : 0;
  height       : 4px;
  z-index      : 999999999;
  display      : none;
}

.zLoaderActive {
  display: block;
}

.zLoaderBar {
  position     : absolute;
  top          : 0;
  right        : 100%;
  bottom       : 0;
  left         : 0;
  width        : 0;
  display      : none;
}

.zLoaderBarActive {
  display  : block;
  animation: borealisBar 1s linear infinite;
}

@keyframes borealisBar {
  0% {
    left : 0%;
    right: 100%;
    width: 0%;
  }

  10% {
    left : 0%;
    right: 75%;
    width: 25%;
  }

  90% {
    right: 0%;
    left : 75%;
    width: 25%;
  }

  100% {
    left : 100%;
    right: 0%;
    width: 0%;
  }
}